import { useJsApiLoader } from '@react-google-maps/api'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { getUserData } from '@returnmates/client-core/src/selectors/user'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import refreshTimeout from '@returnmates/client-core/src/utils/refreshTimeout'
import SnackBars from '@returnmates/ui-core/src/components/SnackBars'
import Spinner from '@returnmates/ui-core/src/components/Spinner'
import AWS from 'aws-sdk'
import { lazy, memo, Suspense, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import Middleware from '../../components/Middleware'
import NotSchedulePickupModal from '../../components/NotSchedulePickupModal'
import PromoDialog from '../../components/PromoDialog'
import config from '../../config'
import {
  ABOUT,
  BLOG,
  BLOGS,
  CAREER,
  CONSOLIDATION,
  CONSOLIDATIONS_DETAILS,
  DISABLED_PATH_FOR_POPUP,
  DRIVE,
  FAQ,
  FIRST_PICKUP,
  FOR_BRANDS,
  FORGOT_PASSWORD,
  HOME,
  LEXERONE_TRIAL,
  LOGIN,
  LUXERONE,
  LUXERONE_FREE,
  MANIFEST,
  MY_ACCOUNT,
  OAUTH,
  PACKAGE_FAQ,
  PATH_FOR_REDIRECT_TO_SWAY,
  PRICING,
  PRIVACY,
  PRIVACY_PARTNER,
  SCHEDULE_PICKUP_SUCCESS,
  SHIPSWAY_URL,
  SIGNUP,
  SWAP,
  TERMS,
  TRACKING,
  TRIPS_TRACKING,
  UNAVAILABLE_AREA,
  UNAVAILABLE_AREA_THANKS,
  UPDATE_PASSWORD,
  UPGRADE,
  ZIP_CODE,
} from '../../constants/paths'
import LuxerOne from '../LuxerOne'
import LuxerOneFree from '../LuxerOneFree'
import LuxerOneTrial from '../LuxerOneTrial'
import useStyles from './styles'

const AboutUs = lazy(() => import('../AboutUs'))
const AddressesPage = lazy(() => import('../Addresses'))
const BlogPage = lazy(() => import('../Blog'))
const BlogsPage = lazy(() => import('../Blogs'))
const CareerPage = lazy(() => import('../Career'))
const CreateFirstPickupPage = lazy(() => import('../CreateFirstPickup'))
const DrivePage = lazy(() => import('../Drive'))
const FAQPage = lazy(() => import('../FAQ'))
const ForBrandsPage = lazy(() => import('../ForBrands'))
const ForgotPasswordPage = lazy(() => import('../ForgotPassword'))
const LoginPage = lazy(() => import('../Login'))
const MainPage = lazy(() => import('../Main'))
const Manifest = lazy(() => import('../Manifest'))
const MyAccount = lazy(() => import('../MyAccount'))
const OauthLoginPage = lazy(() => import('../OauthLogin'))
const PackageFAQ = lazy(() => import('../PackageFAQ'))
const PricingPage = lazy(() => import('../Pricing'))
const PrivacyPage = lazy(() => import('../Privacy'))
const PrivacyPartnerPage = lazy(() => import('../PrivacyPartner'))
const RegistrationPage = lazy(() => import('../Registration'))
const ScheduledPickup = lazy(() => import('../ScheduledPickup'))
const TermsPage = lazy(() => import('../Terms'))
const TrackingPage = lazy(() => import('../Tracking'))
const NewTripsTracking = lazy(() => import('../NewTripsTracking'))
const UnvaibleAreaPage = lazy(() => import('../UnvaibleArea'))
const UnvaibleAreaThanksPage = lazy(() => import('../UnvaibleAreaThanks'))
const UpdatePasswordPage = lazy(() => import('../UpdatePassword'))
const UpgradePlan = lazy(() => import('../UpgradePlan'))
const ZipCodePage = lazy(() => import('../ZipCode'))
const ConsolidationsDetails = lazy(() => import('../ConsolidationsDetails'))

AWS.config.update({
  region: config.aws.region,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.aws.identityPoolId,
  }),
})

function Root() {
  const [isLoading, setIsLoading] = useState(true)
  const [isZipCodeUploaded, setIsZipCodeUploaded] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const classes = useStyles()
  const userData = useSelector(getUserData)
  const dispatch = useDispatch()
  const { isLoaded: isLoadedGoogle } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.google.maps,
    libraries: ['places'],
  })

  const checkIsTokenExist = useCallback(async () => {
    try {
      await refreshTimeout(dispatch, true)
    } finally {
      setIsLoading(false)
    }
  }, [dispatch])

  const getServicedZipCodes = useCallback(async () => {
    try {
      await createAsyncAction(dispatch, actions.getServicedZipCodes.request({ where: {} }))
    } finally {
      setIsZipCodeUploaded(true)
    }
  }, [dispatch])

  useEffect(() => {
    checkIsTokenExist()
  }, [checkIsTokenExist])

  useEffect(() => {
    getServicedZipCodes()
  }, [getServicedZipCodes])

  useEffect(() => {
    const uuidPattern = '[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}'
    const urlRegex = new RegExp(`^/(manifest)/(${uuidPattern})$`, 'i')

    if (
      !window.location.pathname.includes(TRIPS_TRACKING) &&
      !window.location.pathname.includes(TRACKING) &&
      !urlRegex.test(window.location.pathname)
    ) {
      setIsModalOpen(true)
    }
  }, [])

  useLayoutEffect(() => {
    if (PATH_FOR_REDIRECT_TO_SWAY.includes(window.location.pathname)) {
      window.location.href = SHIPSWAY_URL
    }
  }, [])

  if (isLoading || !isLoadedGoogle || !isZipCodeUploaded) {
    return (
      <BrowserRouter>
        <div className={classes.spinnerHandler}>
          <Spinner />
          <Middleware />
          <SnackBars />
        </div>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <SnackBars />
        {isModalOpen && !DISABLED_PATH_FOR_POPUP.includes(window.location.pathname) ? (
          <NotSchedulePickupModal />
        ) : null}

        <PromoDialog />
        {userData ? (
          <Routes>
            <Route path={SCHEDULE_PICKUP_SUCCESS} element={<ScheduledPickup />} />
            <Route path={UPGRADE} element={<UpgradePlan />} />
            <Route path={MY_ACCOUNT} element={<MyAccount />} />
            <Route path={TERMS} element={<TermsPage />} />
            <Route path={PRIVACY} element={<PrivacyPage />} />
            <Route path={PRIVACY_PARTNER} element={<PrivacyPartnerPage />} />
            <Route path={`${FIRST_PICKUP}/*`} element={<CreateFirstPickupPage />} />
            <Route path={ZIP_CODE} element={<ZipCodePage />} />
            <Route path={UNAVAILABLE_AREA} element={<UnvaibleAreaPage />} />
            <Route path={UNAVAILABLE_AREA_THANKS} element={<UnvaibleAreaThanksPage />} />
            <Route path={SWAP} element={<AddressesPage />} />
            <Route path={`${TRACKING}/*`} element={<TrackingPage />} />
            <Route path={`${TRIPS_TRACKING}/*`} element={<NewTripsTracking />} />
            <Route path={BLOGS} element={<BlogsPage />}>
              <Route path={BLOG} element={<BlogPage />} />
            </Route>
            <Route path={CAREER} element={<CareerPage />} />
            <Route path={FOR_BRANDS} element={<ForBrandsPage />} />
            <Route path={DRIVE} element={<DrivePage />} />
            <Route path={MANIFEST} element={<Manifest />} />
            <Route path={PACKAGE_FAQ} element={<PackageFAQ />} />
            <Route path={LUXERONE} element={<LuxerOne />} />
            <Route path={LUXERONE_FREE} element={<LuxerOneFree />} />
            <Route path={LEXERONE_TRIAL} element={<LuxerOneTrial />} />
            <Route path={CONSOLIDATIONS_DETAILS} element={<ConsolidationsDetails />} />
            <Route path={CONSOLIDATION} element={<Navigate to="/" />} />
            <Route path="*" element={<MyAccount />} />
          </Routes>
        ) : (
          <Routes>
            <Route path={SCHEDULE_PICKUP_SUCCESS} element={<ScheduledPickup />} />
            <Route path={LOGIN} element={<LoginPage />} />
            <Route path={SIGNUP} element={<RegistrationPage />} />
            <Route path={ZIP_CODE} element={<ZipCodePage />} />
            <Route path={UNAVAILABLE_AREA} element={<UnvaibleAreaPage />} />
            <Route path={UNAVAILABLE_AREA_THANKS} element={<UnvaibleAreaThanksPage />} />
            <Route path={FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
            <Route path={UPDATE_PASSWORD} element={<UpdatePasswordPage />} />
            <Route path={TERMS} element={<TermsPage />} />
            <Route path={PRIVACY} element={<PrivacyPage />} />
            <Route path={PRIVACY_PARTNER} element={<PrivacyPartnerPage />} />
            <Route path={FIRST_PICKUP} element={<ZipCodePage />} />
            <Route path={OAUTH} element={<OauthLoginPage />} />
            <Route path={PRICING} element={<PricingPage />} />
            <Route path={ABOUT} element={<AboutUs />} />
            <Route path={FAQ} element={<FAQPage />} />
            <Route path={PACKAGE_FAQ} element={<PackageFAQ />} />
            <Route path={SWAP} element={<AddressesPage />} />
            <Route path={`${TRACKING}/*`} element={<TrackingPage />} />
            <Route path={`${TRIPS_TRACKING}/*`} element={<NewTripsTracking />} />
            <Route path={BLOG} element={<BlogPage />} />
            <Route path={BLOGS} element={<BlogsPage />} />
            <Route path={CAREER} element={<CareerPage />} />
            <Route path={HOME} element={<MainPage />} />
            <Route path={DRIVE} element={<DrivePage />} />
            <Route path={MANIFEST} element={<Manifest />} />
            <Route path={LUXERONE} element={<LuxerOne />} />
            <Route path={LUXERONE_FREE} element={<LuxerOneFree />} />
            <Route path={LEXERONE_TRIAL} element={<LuxerOneTrial />} />
            <Route path={CONSOLIDATIONS_DETAILS} element={<ConsolidationsDetails />} />
            <Route path="*" element={<ForBrandsPage />} />
          </Routes>
        )}
        <Middleware />
      </Suspense>
    </BrowserRouter>
  )
}

export default memo(Root)
